import { SelectionChangedEvent } from "ag-grid-community";
import { ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_PARAMETER_DETAIL_COL_DEF, transferRowData } from "presentation/constant/Parameter/ParameterDetailColumnDefinition";
import { useParameterMaintenanceVM } from "presentation/hook/Parameter/useParameterMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useParameterMaintenanceTracked } from "presentation/store/Parameter/ParameterMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const ParameterSubDetailTablePanel: React.FC = () => {
    const [paramMainState] = useParameterMaintenanceTracked();
    const paramMainVM = useParameterMaintenanceVM();

    const { subDetailState, detailState, masterState } = paramMainState;
    const { currentParamDtlEntity, isShowSubDtlTable } = detailState;
    const { parameterSubDtlList } = subDetailState;

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();
    const [selectedRow, setSelectedRow] = useState<ParameterDetailEntity>();

    useEffect(() => {
        const initialScreen = async () => {
            if (isShowSubDtlTable && currentParamDtlEntity?.id
            ) {
                setIsLoading(true);
                try {
                    const results = await Promise.allSettled([
                        paramMainVM.onSearchParamSubDtl(currentParamDtlEntity.id)
                    ]);
                    results?.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {

                        }
                    })
                } catch (error) {
                    setIsLoading(false);
                }
            }
        }
        initialScreen().then(async () => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [currentParamDtlEntity.id, isShowSubDtlTable, paramMainVM])

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_PARAMETER_DETAIL_COL_DEF?.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        paramMainVM.updateSelectedParamSubDtlRows(selectedRows);
        setSelectedRow(selectedRows[0]);

        /*if(selectedRows && selectedRows.length > 0){
            paramMainVM.onParamSubDtlEdit(selectedRows[0]);
        }        */
    }, [paramMainVM])

    const handleEditClick = useCallback(() => {
        if (selectedRow) {
            paramMainVM.onParamSubDtlEdit(selectedRow);
        } else {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        }
    }, [paramMainVM, messageBarVM, selectedRow])

    const handleAddClick = useCallback(async () => {
        paramMainVM.onParamSubDtlAdd();
    }, [paramMainVM])

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];
        if (masterState.isEditable && !masterState.isRead && !subDetailState.isAdd && !subDetailState.isEditable && !detailState.isAdd && !detailState.isEditable) {
            tableBtns.push({
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })

            tableBtns.push({
                id: 'onAddButton',
                icon: 'Icon-add',
                title: 'Add'
            })
        }

        return tableBtns
    }, [masterState.isEditable, masterState.isRead, detailState.isAdd, detailState.isEditable, subDetailState.isAdd, subDetailState.isEditable])

    const memoParamSubDtlTable = useMemo(() => {
        return (
            <div style={{ marginTop: "50px" }}>
                <NbisTable
                    id='parameter-sub-dtl-table'
                    headerLabel={currentParamDtlEntity.parameterDtlCode ?? ""}
                    columns={INITIAL_PARAMETER_DETAIL_COL_DEF?.slice()}
                    data={transferRowData(parameterSubDtlList ?? [])}
                    headerActionButtons={memoTableBtns}
                    showPaginator={false}
                    editable={false}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    isRowHighligted={true}
                    selectionMode={false}
                    rowSelection={"single"}
                    handleSelectionChanged={handleSelectionChange}
                    onEditButton={handleEditClick}
                    onAddButton={handleAddClick}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 600px)"
                    ref={gridRef} />
            </div>
        );
    }, [currentParamDtlEntity.parameterDtlCode, parameterSubDtlList, memoTableBtns, handleSelectionChange, handleEditClick, handleAddClick])

    return <>
        <TableWrapper>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoParamSubDtlTable}
        </TableWrapper>
    </>;

}

export default memo(ParameterSubDetailTablePanel);