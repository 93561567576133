import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ParameterMaintenanceConstant } from "./ParameterMaintenanceConstant";

const SCREEN_CONSTANT = ParameterMaintenanceConstant;
let dateFieldList: string[] = [];
export const INITIAL_PARAMETER_DETAIL_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.Detail.PARAMETER_DETAIL_CODE,
        field: "parameterDtlCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 330,
        /*checkboxSelection: true,
        headerCheckboxSelection: true,*/
    },
    {
        headerName: SCREEN_CONSTANT.Detail.PARAMETER_DETAIL_DESC,
        field: "parameterDtlDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 650,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    }

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, [], [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, [], [], externalFnctions);
}
